import React from 'react'
import $ from 'jquery'

import Element from './elements/Element'

class ScormExport {
  constructor(module, urls) {
    this.modules = [];
    this.urls = urls;
    this.modules.push(module)
  }

  getModules() {
    var modules = this.modules.map((module, moduleIndex) => {
      var elements = module.elements.map((element) => {
        var e = new Element(element, this.urls);
        return <Element element={element} urls={this.urls} />
      })
      var introslider = false
      if(elements.length === 1 && module.elements[0].type === 'introslider' && module.settings.headline == '') {
        introslider = true
      }
      return(
        <div key={module.id} className={'module' + (introslider ? ' full-width completed' : '') + (introslider && moduleIndex === 0 ? ' scrolled-to' : '')}>
          <div className="module-container">
            {
              module.settings.headline_show && module.settings.headline !== '' ?
                <h1>{module.settings.headline}</h1>
              :
                ''
            }
            <div className="elements">{elements}</div>
            {
              !introslider ?
                <button className="button next" data-completed-label="Fertig">Weiter</button>
              :
                ''
            }
          </div>
        </div>
      )
    })
    return modules
  }

  render() {
    var modules = this.getModules();
    return(
      <div id="export-preview">
        <div id="wbt" className="wbt export-preview">
          <div className="wbt-container">
            <div className="modules">
              {modules}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScormExport
